import {evite} from 'evite';
import {SignInView} from './sign_in_out';
import {shareLinkToFacebook, shareLinkToTwitter} from '../common/social_sharing';

export const navBarSetup = (adHeight) => {
  window.evite.resolve('sign-in-view', new SignInView());
  window.shareLinkToFacebook = shareLinkToFacebook;
  window.shareLinkToTwitter = shareLinkToTwitter;
};

evite.navSetup = evite.navSetup || {};
Object.assign(evite.navSetup, exports);
