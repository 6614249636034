require('intersection-observer');

// status codes
export const STATUS_RENDERED = 'rendered';
export const STATUS_NOT_RENDERED = 'not_rendered';
export const STATUS_LOADED = 'loaded';

export const STATUS_NOT_FOUND = 'not_found';
export const STATUS_VIEWED = 'viewed';
export const STATUS_GLIMPSE = 'glimpse';
export const STATUS_NOT_VIEWED = 'not_viewed';

// how long must 50% of the ad be visible for to be considered viewed
const VIEWABLE_MS = 1000;

export class ViewabilityTracker {
  // key: slotId
  slots = {};

  // key: slotId, value: dom element
  elements = {};

  // if anything renders or changes
  needsToSend = false;

  constructor() {
    if (window.evite.viewability) return window.evite.viewability;
    this.observer = new IntersectionObserver(this.updateStatus, {
      threshold: 0.5,
    });
    window.evite.viewability = this;

    document.addEventListener('visibilitychange', this.handleVisibilityChange, false);

    window.addEventListener('beforeunload', this.sendData, false);
  }

  handleVisibilityChange = () => {
    // reschedule timers after returning
    if (document.hidden) {
      Object.values(this.slots).forEach((ad) => {
        if (ad.timeout) {
          clearTimeout(ad.timeout);
          ad.timeout = 'hidden';
        }
      });
      return;
    }

    // reschedule on coming back
    Object.values(this.slots).forEach((ad) => {
      if (ad.timeout === 'hidden') {
        ad.timeout && clearTimeout(ad.timeout);
        ad.timeout = setTimeout(this.timerFunc(ad), VIEWABLE_MS);
      }
    });
  };

  sendData = () => {
    if (!window.navigator?.sendBeacon) return;
    if (!this.needsToSend) return;
    if (!evite.features.isEnabled('viewability_tracker')) return;

    const data = new FormData();
    data.append('rows', JSON.stringify(Object.values(this.slots)));
    data.append(
      'cm',
      JSON.stringify({
        sessionId: window.client_data?.session_id,
        requestId: window.client_data?.request_id,
        userId: window.client_data?.user_identifier,
        page: window.location.pathname,
        hostname: window.location.hostname,
      })
    );
    window.navigator.sendBeacon(
      // local dev:
      // 'https://localhost:3000/eventhorizon/viewability/',
      '/eventhorizon/viewability/',
      data
    );
    this.needsToSend = false;
  };

  trackAd = (slotId, el = null) => {
    if (typeof window.IntersectionObserver === 'undefined') {
      return;
    }
    this.slots[slotId] = {
      slotId,
      glimpsed: false,
      rendered: STATUS_NOT_RENDERED,
      viewed: STATUS_NOT_VIEWED,
      dfpViewed: STATUS_NOT_VIEWED,
      timeout: null,
    };
    this.elements[slotId] = el || document.getElementById(slotId);

    if (this.elements[slotId]) {
      this.observer.observe(this.elements[slotId]);
    } else {
      this.slots[slotId].rendered = STATUS_NOT_FOUND;
      this.slots[slotId].viewed = STATUS_NOT_FOUND;
    }
  };

  finishAd = (slotId) => {
    this.observer.unobserve(this.elements[slotId]);
    if (this.slots[slotId].timeout) {
      clearTimeout(this.slots[slotId].timeout);
    }
    this.slots[slotId] = undefined;
  };

  adRendered = (slot, properties) => {
    this.needsToSend = true;
    const slotId = slot.getSlotElementId();
    const ad = this.slots[slotId];
    if (ad) {
      Object.assign(ad, properties);
      ad.adUnitPath = slot.getAdUnitPath();
      ad.rendered = STATUS_RENDERED;
    }
  };

  adLoaded = (slot) => {
    this.needsToSend = true;
    const slotId = slot.getSlotElementId();
    if (this.slots[slotId]) {
      const rect = this.elements[slotId].getBoundingClientRect();
      if (rect.width * rect.height > 0) {
        this.slots[slotId].rendered = STATUS_LOADED;
      }
      // restart observing since we probably ignored some events while it was loading
      this.observer.unobserve(this.elements[slotId]);
      this.observer.observe(this.elements[slotId]);
    }
  };

  adViewed = (slot) => {
    this.needsToSend = true;
    const slotId = slot.getSlotElementId();
    if (this.slots[slotId]) {
      this.slots[slotId].dfpViewed = STATUS_VIEWED;
    }
  };

  refresh = () => {
    this.sendData();
    this.reset();
  };

  reset = () => {
    for (const ad of Object.values(this.slots)) {
      if (ad.timeout) {
        clearTimeout(ad.timeout);
      }
      this.trackAd(ad.slotId);
    }
    this.needsToSend = false;
  };

  updateStatus = (entries) => {
    for (const entry of entries) {
      const slotId = entry.target.id;
      const ad = this.slots[slotId];
      if (!ad || ad.viewed === STATUS_VIEWED) {
        continue;
      }

      const rect = entry.target.getBoundingClientRect();
      if (rect.width * rect.height === 0) {
        // some ads render with nothing, make sure they're not counted
        ad.rendered = STATUS_NOT_RENDERED;
        return;
      }

      // glimpse start
      if (entry.intersectionRatio >= 0.5 && ad.timeout === null) {
        ad.glimpsed = true;
        if (ad.rendered === STATUS_LOADED) {
          ad.viewed = STATUS_GLIMPSE;
          ad.timeout = setTimeout(this.timerFunc(ad), VIEWABLE_MS);
        }
      }

      // glimpse end
      if (entry.intersectionRatio < 0.5) {
        if (ad.timeout) {
          clearTimeout(ad.timeout);
          ad.timeout = null;
        }
        ad.viewed = STATUS_NOT_VIEWED;
      }
    }
  };

  // make a timer function for the given slotId
  timerFunc = (ad) => () => {
    // still glimpsing, set viewed
    if (ad.viewed === STATUS_GLIMPSE) {
      if (document.hidden) {
        // tab isn't visible so this view doesn't count until it comes back
        ad.timeout = 'hidden';
        return;
      }

      ad.glimpsed = false;
      ad.viewed = STATUS_VIEWED;
    } else {
      ad.viewed = STATUS_NOT_VIEWED;
    }
    ad.timeout = null;
  };
}
