import {ViewabilityTracker} from '~/ads/viewability';

export const setupRefresh = (store, adObj, adSlot) => {
  console.log(`setting up refresh for ad - ${adObj.slotId}`);

  if (!evite.features.isEnabled('auto_refresh_ads', false)) return;
  const viewability = new ViewabilityTracker();

  const refreshConfig = {
    startTime: Date.now(),
    refreshes: 0,
    refreshLimit: adObj.refreshTime || null,
    refreshTime: adObj.refreshTime || 30,
    remainingTime: 30,
    viewability,
    resetRemaining: (startTime) => {
      refreshConfig.startTime = startTime;
      refreshConfig.remainingTime = refreshConfig.refreshTime;
    },
  };

  let adTimer = startRefreshTimer(store, adObj, adSlot, refreshConfig);
  // logic for handling pausing of the ad refresh timer
  const pauseRefreshTimer = () => {
    refreshConfig.remainingTime -= Math.round((Date.now() - refreshConfig.startTime) / 1000);
    if (adTimer) clearTimeout(adTimer);
  };

  // handler function for starting/stopping of the ad refresh timer
  const handleAdVisibilityChange = (visible, adObj) => {
    if (!visible || document.hidden) {
      pauseRefreshTimer();
    } else {
      if (adTimer) clearTimeout(adTimer);
      adTimer = startRefreshTimer(store, adObj, adSlot, refreshConfig);
    }
  };

  const refreshCallbacks = {
    impressionViewable: () => {
      if (adTimer) clearTimeout(adTimer);
      adTimer = startRefreshTimer(store, adObj, adSlot, refreshConfig);
    },
    visibilityChanged: handleAdVisibilityChange,
  };

  evite.when('dfp-setup').then((dfpStore) => {
    // Wait for the prebid auction to end so we have access to the IAS
    document.addEventListener('visibilitychange', handleAdVisibilityChange);
  });
  return refreshCallbacks;
};

export const startRefreshTimer = (store, adObj, adSlot, refreshConfig) => {
  let {refreshes, refreshLimit, remainingTime, viewability, resetRemaining} = refreshConfig;
  // reset startTime whenever the timer restarts
  const startTime = Date.now();
  return setTimeout(() => {
    // if using optional refreshLimit, logic to handle generating a new adSlot
    console.log(`Refreshing ad - ${adObj.slotId}`);

    if (refreshLimit !== null && refreshes >= refreshLimit) {
      googletag.destroySlots([adSlot]);
      viewability.finishAd(adSlot.getSlotElementId());
      adObj = store.cycleAdSlot(adObj);
      adSlot = store.makeSingleAdCall(adObj);
      viewability.trackAd(adSlot.getSlotElementId());
      refreshes = 0;
    } else {
      store.refresh([adObj], true, [adSlot]);
      refreshes++;
    }
    resetRemaining(startTime); // reset the timer timeout each time it fires
  }, remainingTime * 1000);
};

export const slotCanRefresh = (store, slotId) => !store.refreshDisabled.has(slotId);
