import {Emitter} from 'evite';
import urls from 'urls';
import {saveUserDataToLocalStorage} from '~/common/auth/utils';

export class SignInView extends Emitter {
  user = null;

  signed_in = false;

  constructor() {
    super();
    this.el = document.getElementById('header');
    this.signed_in = this.check_signed_in();
  }

  get_user() {
    // evite_cookie = window.evite.cookie('x-evite-auth')
    // evite_cookie && evite_cookie.split(':')[0]
    // doh, the auth cookie changed and we need some way to keep this functionality
    return window.tags.dl.email;
  }

  check_signed_in() {
    let r = 0;
    try {
      r = window.tags.dl.is_logged_in === 1;
    } finally {
      return r;
    }
  }

  get_next() {
    // for sign in and register, return the 'next' param if appropriate
    if (window.location.protocol === 'https:') {
      return getURLParameter('next');
    }

    if (!window.location.pathname.match(/(`${urls.get('login')`}|register).*/)) {
      let n = window.location.pathname;
      if (window.location.search) {
        n += window.location.search;
      }
      return n;
    }
    return null;
  }

  set_signed_in_state(full_name) {
    const loggedIn = document.querySelector('.logged_in');
    const signedIn = document.querySelector('.signed_in');
    const loggedOut = document.querySelector('.logged_out');
    const signedOut = document.querySelector('.signed_out');
    const signIn = document.querySelector('.signin');
    const name = document.querySelector('li.user .full_name');
    if (loggedIn) loggedIn.style.display = 'block';
    if (signedIn) loggedIn.style.display = 'block';
    if (loggedOut) loggedIn.style.display = 'none';
    if (signedOut) loggedIn.style.display = 'none';
    if (signIn) loggedIn.style.display = 'none';
    this.signed_in = true;
    this.user = full_name;
    if (name) name.innerHTML = full_name;
    return this.dispatchEvent(new CustomEvent('success'));
  }

  set_signed_in(full_name) {
    if (!window.prevent_page_reload_on_login) {
      return window.location.reload();
    }
    return this.set_signed_in_state(full_name);
  }

  checkConnected(onConnectCb) {
    console.warn('Calling ajax_authenticated api');
    window.evite
      .fetch(urls.get('ajax_authenticated'), {
        credentials: 'include',
        headers: {
          'X-CSRFToken': evite.cookie('csrftoken'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => response.json())
      .then((data) => {
        console.warn('ajax_authenticated data ', data);

        if (data && data.full_name) {
          console.warn('authenticated for name: ', data.full_name);
          this.set_signed_in(data.full_name);
          saveUserDataToLocalStorage(data);
          if (evite.isFunction(onConnectCb)) {
            console.warn('calling authenticated callback!');
            onConnectCb(data.full_name, data.user_id, data.subscription_plan_name, data.email);
          }
        }
      })
      .catch((error) => {
        console.warn('authenticated Failed ', error);
        localStorage.removeItem('userData');
        evite.error(`error: ${error}`);
      });
  }

  facebook_connect(opts) {
    window.tags.page_event('facebook_connect');

    const w = 580;
    const h = 400;
    const left = screen.width / 2 - w / 2;
    const top = screen.height / 2 - h / 2;
    const popup = window.open(
      urls.get('facebook_connect'),
      'fb_auth',
      `width=${w},height=${h},top=${top},left=${left}`
    );
    if (popup == null) {
      if (!window.prevent_page_reload_on_login) {
        window.location.assign(
          `${urls.get('login')}?next=${encodeURIComponent(window.location.pathname)}`
        );
      } else {
        alert(
          'We tried to open a popup window to connect to Facebook. Please enable popup windows in your browser.'
        );
      }
      return;
    }

    const check_connected = () => {
      if (popup.closed) {
        const url = urls.get('ajax_authenticated');
        window.evite
          .fetch(url, {
            credentials: 'include',
            headers: {
              'X-CSRFToken': evite.cookie('csrftoken'),
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          })
          .then((response) => response.json())
          .then((data) => {
            if (data && data.full_name) {
              this.set_signed_in(data.full_name);
              saveUserDataToLocalStorage(data);
              if (opts.onConnect) {
                return opts.onConnect(
                  data.full_name,
                  data.user_id,
                  data.subscription_plan_name,
                  data.email
                );
              }
            }
          })
          .catch((error) => {
            localStorage.removeItem('userData');
            window.console.log(`Error while posting to ${url} errorThrown=${error}`);
          });
      } else {
        return setTimeout(check_connected, 300);
      }
    };

    return check_connected();
  }

  google_plus_connect(opts) {
    window.tags.page_event('google_plus_connect');

    const w = 580;
    const h = 400;
    const left = screen.width / 2 - w / 2;
    const top = screen.height / 2 - h / 2;
    const popup = window.open(
      urls.get('google_plus_connect'),
      'gp_auth',
      `width=${w},height=${h},top=${top},left=${left}`
    );
    if (popup == null) {
      if (!window.prevent_page_reload_on_login) {
        window.location.assign(
          `${urls.get('login')}?next=${encodeURIComponent(window.location.pathname)}`
        );
      } else {
        alert(
          'We tried to open a popup window to connect to Google Plus. Please enable popup windows in your browser.'
        );
      }
      return;
    }

    const check_connected = () => {
      if (popup.closed) {
        window.evite
          .fetch(urls.get('ajax_authenticated'), {
            credentials: 'include',
            headers: {
              'X-CSRFToken': evite.cookie('csrftoken'),
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          })
          .then((response) => response.json())
          .then((data) => {
            if (data && data.full_name) {
              this.set_signed_in(data.full_name);
              saveUserDataToLocalStorage(data);
              if (opts.onConnect) {
                opts.onConnect(
                  data.full_name,
                  data.user_id,
                  data.subscription_plan_name,
                  data.email
                );
              }
            }
          })
          .catch((error) => {
            localStorage.removeItem('userData');
            if (window.console !== null) {
              console.log(`error: ${error}`);
            }
          });
      } else {
        return setTimeout(check_connected, 300);
      }
    };

    return check_connected();
  }

  apple_connect(opts) {
    let onConnectCb = opts;
    if (typeof opts === 'object') onConnectCb = opts.onConnect;
    const w = 580;
    const h = 400;
    const left = screen.width / 2 - w / 2;
    const top = screen.height / 2 - h / 2;
    const popup = window.open(
      urls.get('apple_connect'),
      'apple_auth',
      `width=${w},height=${h},top=${top},left=${left}`
    );
    let intervalID;

    if (popup == null) {
      if (!window.prevent_page_reload_on_login) {
        window.location.assign(
          `${urls.get('login')}?next=${encodeURIComponent(window.location.pathname)}`
        );
      } else {
        alert(
          'We tried to open a popup window to connect to Apple. Please enable popup windows in your browser.'
        );
      }
      return;
    }

    if (popup.closed) {
      console.warn('apple popup closed!!!');
      return this.checkConnected(onConnectCb);
    }
    console.warn('apple popup opened!!!');
    intervalID = window.setInterval(() => {
      console.warn('Time Interval Cb');
      if (popup.closed) {
        console.warn('popup.closed. Checking authenticate');
        this.checkConnected(onConnectCb);
        clearInterval(intervalID);
      }
    }, 500);
  }
}
