export const loadMatrices = async (zones = []) => {
  const platform = evite.detects.isMobile ? 'mobile' : 'desktop';
  try {
    if (!zones.length) {
      const matrix = await import(`./${platform}/${window.client_data.zone}.json`);
      evite.resolve('matrix', matrix.default);
      return {
        [window.client_data.zone]: matrix.default,
      };
    }
    return zones.reduce(async (obj, zoneName) => {
      const matrix = await import(`./${platform}/${zoneName}.json`);
      obj[zoneName] = matrix.default;
    }, {});
  } catch (e) {
    return {};
  }
};
