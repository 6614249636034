function storeUTM() {
  const params = new URLSearchParams(window.location.search);
  const source = params.get('utm_source');
  if (!source) return;
  const fields = ['source', 'medium', 'campaign', 'content', 'term'];
  const data = {};
  fields.forEach((field) => {
    data[field] = params.get(`utm_${field}`) || 'None';
  });
  localStorage.setItem('utm', JSON.stringify({timeStamp: new Date(), data}));
}

function getUTM() {
  const utm = JSON.parse(localStorage.getItem('utm') || '{}');
  if (!utm.timeStamp) return null;
  const expires = new Date(utm.timeStamp);
  expires.setDate(expires.getDate() + 30);
  if (new Date() < expires) return utm.data;
  localStorage.removeItem('utm');
  return undefined;
}

function saveUTMToObj(obj, fieldName = 'utm') {
  const utmData = getUTM();
  // eslint-disable-next-line no-param-reassign
  if (utmData) obj[fieldName] = utmData;
  return obj;
}
window.saveUTMToObj = saveUTMToObj;
window.getUTM = getUTM;

storeUTM();
