import {observable, computed, action, reaction, toJS} from 'mobx';
import {getURLParameter} from '~/common/utils';
import {prebidPromise} from '~/ads/prebid';
import {ViewabilityTracker} from '~/ads/viewability';
import {refresh, slotCanRefresh, setupRefresh} from '~/ads/refreshLogic';

export class DfpStore {
  @observable accessor zone = '';

  @observable accessor paramList = [];

  @observable accessor IASTargetingParams = {};

  @observable accessor canTarget = window.DISABLE_PROGRAMMATIC_ADS
    ? window.DISABLE_PROGRAMMATIC_ADS
    : true;

  @observable accessor matrix = {};

  ads = {};

  viewability = new ViewabilityTracker();

  refreshDisabled = new Set();

  PREBID_TIMEOUT = 1200;

  A9_TIMEOUT = 2000;

  SLOT_LIMIT = 5; // hardcoded to match what is set up in the ad server

  constructor() {
    this.zone = window.client_data.zone;
    this.paramList = window.paramList || '';
    evite.when('dfp-matrix').then(() => {
      googletag.cmd.push(() => {
        this.setupDfp();
      });
    });
  }

  compactUrl() {
    const slots = Array.prototype.slice.apply(arguments);
    const compactSlots = slots.filter((value) => Boolean(value));
    const repeatedSlashes = /\/{2,}/g;
    const trailingSlashes = /\/+$/;
    return compactSlots.join('/').replace(repeatedSlashes, '/').replace(trailingSlashes, '');
  }

  @computed get desktopUrl() {
    return this.compactUrl(
      this.DESKTOP_ID,
      this.DESKTOP_SLOT_1,
      this.DESKTOP_SLOT_2,
      this.DESKTOP_SLOT_3
    );
  }

  @computed get mobileUrl() {
    return this.compactUrl(this.MOBILE_ID, this.MOBILE_SLOT_1, this.MOBILE_SLOT_2);
  }

  @computed get platform() {
    return evite.detects.isMobile ? this.MOBILE_ID : this.DESKTOP_ID;
  }

  @computed get platformUrl() {
    return evite.detects.isMobile ? this.mobileUrl : this.desktopUrl;
  }

  @computed get hostDomain() {
    const parts = window.location.hostname.split('.');
    return parts[parts.length - 2];
  }

  addGPTParam(k, v) {
    if (v !== null) {
      googletag.pubads().setTargeting(k, `${v}`);
      window.paramList += `${k}=${v}&`;
    }
  }

  getGPTParam(arr) {
    if (!(arr instanceof Array)) {
      arr = [arr];
    }

    for (const k of arr) {
      if (window.client_data && window.client_data.hasOwnProperty(k)) {
        return window.client_data[k];
      }
      if (window.dl.dl && window.dl.dl.hasOwnProperty(k)) {
        return window.dl.dl[k];
      }
    }

    return null;
  }

  loadParams = (refresh) => {
    googletag.cmd.push(() => {
      if (pbjs && pbjs.getUserIds().idl_env) {
        this.addGPTParam('IDL', 1);
      } else {
        this.addGPTParam('IDL', 0);
      }

      this.addGPTParam('rar', this.getGPTParam('user_age_range'));
      this.addGPTParam('login', this.getGPTParam('is_logged_in'));
      this.addGPTParam('host', this.getGPTParam('is_host'));
      this.addGPTParam('g', this.getGPTParam('user_gender'));
      this.addGPTParam('city_ps', this.getGPTParam('user_city'));
      this.addGPTParam('zip_ps', this.getGPTParam('user_zip_code'));
      this.addGPTParam('pt', this.getGPTParam(['pagetype', 'zone']));
      this.addGPTParam('page_url', window.location.href);
      this.addGPTParam('demo', getURLParameter('demo'));
      this.addGPTParam('domain', this.hostDomain);
      const {zone} = window.client_data;

      if (this.zone !== 'myevite') {
        let lat;
        let long = null;
        if (this.getGPTParam('event_geo_location')) {
          const geo = this.getGPTParam('event_geo_location').split(',');
          lat = geo[0].trim();
          long = geo[1].trim();
        }
        this.addGPTParam('charity', this.getGPTParam('charity'));
        this.addGPTParam('cj_aid', this.getGPTParam('cj_aid'));
        this.addGPTParam('cj_oid', this.getGPTParam('cj_pid'));
        this.addGPTParam('dc_ref', this.getGPTParam('dc_ref'));
        this.addGPTParam('due', this.getGPTParam('event_days_until'));
        this.addGPTParam('doe', this.getGPTParam('event_day'));
        this.addGPTParam('dur', this.getGPTParam('event_duration'));
        this.addGPTParam('eid', this.getGPTParam('event_id'));
        this.addGPTParam('em', this.getGPTParam('evc'));
        this.addGPTParam('est', this.getGPTParam('event_time'));
        this.addGPTParam('etn', this.getGPTParam('event_age_of_child'));
        this.addGPTParam('ev_hm', this.getGPTParam('ev_hm'));
        this.addGPTParam('ev_lat', lat);
        this.addGPTParam('ev_long', long);
        this.addGPTParam('f', this.getGPTParam('event_step'));
        this.addGPTParam('filter', this.getGPTParam('filter_list'));
        this.addGPTParam('gifting', this.getGPTParam('event_gifting_enabled'));
        this.addGPTParam('kw', this.getGPTParam('kw'));
        this.addGPTParam('k', this.getGPTParam('KEYWORD_ID'));
        this.addGPTParam('response', this.getGPTParam('response'));
        this.addGPTParam('rm', this.getGPTParam('event_rsvp_maybe'));
        this.addGPTParam('rn', this.getGPTParam('event_rsvp_no'));
        this.addGPTParam('ru', this.getGPTParam('event_rsvp_undecided'));
        this.addGPTParam('rt', this.getGPTParam('event_rsvp_total'));
        this.addGPTParam('ry', this.getGPTParam('event_rsvp_yes'));
        this.addGPTParam('si', this.getGPTParam('session_id'));
        this.addGPTParam('state_ctxt', this.getGPTParam('event_state'));
        this.addGPTParam('template', this.getGPTParam('template'));
        this.addGPTParam('tkg', this.getGPTParam('t_g'));
        this.addGPTParam('ty', this.getGPTParam('template_type'));
        this.addGPTParam('zip_ctxt', this.getGPTParam('event_zip'));
        this.addGPTParam('virtual', this.getGPTParam('virtual'));
        this.addGPTParam('miles', this.getGPTParam('event_guest_distance'));
        this.addGPTParam('refresh', refresh ? 1 : 0);

        if (!['signin', 'partner_page'].includes(this.zone)) {
          this.addGPTParam('srnd', this.getGPTParam('surround_id'));
        }
      }

      const reg_names = this.getGPTParam('reg_names');

      if (reg_names) {
        // Flatten, lowercase and strip invalid chars from reg_names array
        const registry = reg_names.map((name) => name.toLowerCase().replace(/\W+/g, '')).join(',');

        this.addGPTParam('registry', registry);
      }

      const gpt = document.location.pathname.match(/^\/c\/([^/]*)/i);
      if (gpt) {
        this.addGPTParam('cp', gpt[1]);
      }
    });
  };

  adEventListeners = (adObj, callbacks = {}) => {
    googletag
      .pubads()
      .addEventListener('slotRenderEnded', (event) => {
        const {slot, ...rest} = event;
        if (!slot) {
          return;
        }
        if (slot.getSlotElementId() === adObj.slotId) {
          evite.trackSlotEvent('slotRenderEnded', {
            slotId: slot.getSlotElementId(),
            adUnitPath: slot.getAdUnitPath(),
            contentUrl: slot.getContentUrl(),
            ...rest,
          });
          this.viewability.adRendered(slot, rest);
          // Perform callback after slot renders
          const callback = window[adObj.callbackName];
          if (callback) {
            callback(event);
          }
        }
      })
      .addEventListener('slotOnload', (event) => {
        const {slot, ...rest} = event;
        if (!slot) {
          return;
        }
        if (slot.getSlotElementId() === adObj.slotId) {
          evite.trackSlotEvent('slotOnload', {
            slotId: slot.getSlotElementId(),
            ...rest,
          });
        }
        this.viewability.adLoaded(slot);
      })
      .addEventListener('impressionViewable', (event) => {
        const {slot, ...rest} = event;
        if (!slot) {
          return;
        }
        if (slot.getSlotElementId() === adObj.slotId) {
          evite.trackSlotEvent('impressionViewable', {
            slotId: slot.getSlotElementId(),
            ...rest,
          });
          if (callbacks && typeof callbacks.impressionViewable === 'function') {
            callbacks.impressionViewable();
          }
        }
        this.viewability.adViewed(slot);
      })
      .addEventListener('slotVisibilityChanged', (event) => {
        const {slot, ...rest} = event;
        if (!slot) return;
        if (slot.getSlotElementId() === adObj.slotId) {
          if (callbacks && typeof callbacks.visibilityChanged === 'function') {
            if (event.inViewPercentage < 50) {
              callbacks.visibilityChanged(false, adObj);
            } else {
              callbacks.visibilityChanged(true, adObj);
            }
          }
        }
      });
  };

  displayAdSlot = (adObj) => {
    this.viewability.trackAd(adObj.slotId);

    // initial load disabled
    window.googletag.cmd.push(() => {
      googletag.display(adObj.slotId);
    });
  };

  /**
   * This function will take the current adObj and update its slotName
   * to use the next available slot. The slots will cycle once they
   * reach the SLOT_LIMIT hardcoded above. Slot 0 is represented without
   * a number.
   */
  cycleAdSlot = (adObj) => {
    const {baseSlotName, slotName} = adObj;

    const currentSlot = parseInt(slotName.replace(baseSlotName, '')) || 0;
    const newSlot = currentSlot === this.SLOT_LIMIT ? '' : currentSlot + 1;
    const newSlotName = `${baseSlotName}${newSlot}`;

    return {
      ...adObj,
      slotName: newSlotName,
    };
  };

  setTargeting = (adSlot, adObj) => {
    const targets = adObj.targeting;
    if (targets) {
      for (const [key, value] of Object.entries(targets)) {
        adSlot.setTargeting(key, value);
      }
    }
  };

  registerOopSlot = (adObj, renderedAdArray, callbacks) => {
    const adSlot = googletag.defineOutOfPageSlot(adObj.slotName, adObj.slotId);
    adSlot.addService(googletag.pubads());
    this.setTargeting(adSlot, adObj);
    this.displayAdSlot(adObj);
    renderedAdArray.push(adSlot);
    return adSlot;
  };

  registerSlot = (adObj, renderedAdArray, callbacks) => {
    const adSlot = googletag.defineSlot(
      adObj.slotName,
      adObj.sizes.slice().map((x) => x.slice()),
      adObj.slotId
    );
    adSlot.addService(googletag.pubads());
    this.setTargeting(adSlot, adObj);
    this.displayAdSlot(adObj);
    // if (sizeMapping) {
    //   adSlot.defineSizeMapping(sizeMapping);
    // }
    renderedAdArray.push(adSlot);
    return adSlot;
  };

  defineAutoAds = (adArray = [], renderedAdArray = [], renderedOopArray = [], promiseName = '') => {
    const slotStartPromises = [];
    adArray.forEach((adObj) => {
      const pStart = new Promise((resolveStart, rejectStart) => {
        window.googletag.cmd.push(() => {
          let adEventCallbacks = {};
          let adSlot;
          if (adObj.oop) {
            adSlot = this.registerOopSlot(adObj, renderedOopArray);
          } else {
            adSlot = this.registerSlot(adObj, renderedAdArray);
          }
          if (adSlot) {
            evite.trackSlotEvent('slotDefined', adObj);
            resolveStart(adSlot);
          } else {
            rejectStart(adObj.slotName);
          }
          if (adObj.refresh && slotCanRefresh(this, adObj.slotId)) {
            adEventCallbacks = Object.assign(adEventCallbacks, setupRefresh(this, adObj, adSlot));
          }
          this.adEventListeners(adObj, adEventCallbacks);
        });
      });
      slotStartPromises.push(pStart);
    });

    Promise.all(slotStartPromises).then(() => {
      if (promiseName) evite.resolve(promiseName, renderedAdArray);
    });
  };

  @action addIASTargetingToSlot = (slot) => {
    for (const [key, value] of Object.entries(this.IASTargetingParams)) {
      slot.setTargeting(key, value);
    }
    return slot;
  };

  @action setIASTargeting = (params) => {
    this.IASTargetingParams = params;
  };

  getIASTargeting = () => toJS(this.IASTargetingParams);

  setupDfp = () => {
    window.googletag = window.googletag || {cmd: []};
    googletag.cmd.push(() => {
      googletag.pubads().collapseEmptyDivs();
      // Turn off the initial load, so display call in defineAutoAds does not render final creative.
      googletag.pubads().disableInitialLoad();
      googletag.pubads().enableSingleRequest();

      googletag.enableServices();
    });
    evite.resolve('dfp-setup', this);
  };

  makeAdCalls = (adArray, renderedAdArray, renderedOopArray, promiseName) => {
    this.defineAutoAds(adArray, renderedAdArray, renderedOopArray, promiseName);
    evite.resolve('dfp', evite.dfp);
    evite.when(promiseName).then(() => {
      const bidders = [];
      this.canTarget && bidders.push(prebidPromise(adArray));
      evite.trackTimingEvent('headerBiddingStarted', {
        bidderNumber: bidders.length,
      });
      Promise.all(bidders).then(() => {
        this.canTarget && this.loadParams();
        const tempAdArray = renderedAdArray.slice();
        googletag.cmd.push(() => {
          googletag.pubads().refresh(tempAdArray, {changeCorrelator: false});
          evite.trackTimingEvent('slotDfpRequestCreative');
        });
        pbjs.initAdserverSet = false;
      });
    });
  };

  // for compatibility. we don't need to debounce since
  // we're tracking which ads have finished loading
  refreshAds = evite.debounce(
    () => (refresh ? refresh(this) : {}),
    window.adrefresh_debounced_timer || 500,
    false
  );

  makeSingleAdCall = (adObj, callbacks) => {
    const adSlot = this.registerSlot(adObj, [], callbacks);
    const bidders = [];
    this.canTarget && bidders.push(prebidPromise([adObj]));
    let adEventCallbacks = {};
    Promise.all(bidders).then(() => {
      this.canTarget && this.loadParams();
      googletag.cmd.push(() => {
        googletag.pubads().refresh([adSlot], {changeCorrelator: false});
        if (adObj.refresh && slotCanRefresh(this, adObj.slotId))
          adEventCallbacks = Object.assign(adEventCallbacks, setupRefresh(this, adObj, adSlot));
      });
      pbjs.initAdserverSet = false;
    });
    this.adEventListeners(adObj, adEventCallbacks);
    return adSlot;
  };

  // support old way to call refresh ads, with no ad in the method
  handleGlobalAutoAds = () => this.refresh(window.evite.dfp.autoAds);

  // These methods are called within template wrappers on the dfp servers
  // to disable or enable ad refresh at the slot level
  disableRefreshFor(adSlotId) {
    this.refreshDisabled.add(adSlotId);
  }

  enableRefreshFor(adSlotId) {
    this.refreshDisabled.delete(adSlotId);
  }

  refresh = (adArray, setRefreshParam = false, adSlotArray) => {
    if (!adArray) {
      return this.handleGlobalAutoAds();
    }

    const refreshableAdArray = [];
    const refreshableAdSlotArray = [];
    for (let i = 0; i < adArray.length; i++) {
      // disable refresh for direct sold
      // (refreshDisabled Set is built by a creative template wrapper
      // inside of the ad server using class methods
      // "window.evite.dfp.disableRefreshFor(...)")
      if (slotCanRefresh(this, adArray[i].slotId)) {
        refreshableAdArray.push(adArray[i]);
        if (adSlotArray && i < adSlotArray.length) refreshableAdSlotArray.push(adSlotArray[i]);
      }
    }
    googletag.cmd.push(() => {
      const bidders = [];
      this.viewability.refresh();
      this.canTarget && bidders.push(prebidPromise(refreshableAdArray));
      Promise.all(bidders).then(() => {
        this.canTarget && this.loadParams(setRefreshParam);
        googletag.cmd.push(() => {
          if (refreshableAdSlotArray.length) {
            googletag.pubads().refresh(refreshableAdSlotArray);
          }
        });
        if (evite.dfp.canTarget) {
          setTimeout(() => {
            pbjs.initAdserverSet = false;
          }, this.PREBID_TIMEOUT);
        }
      });
    });
  };
}
