import {popupWindow} from './utils';

export function shareLinkToFacebook(shareUrl, pushData = null, popupOptions = null) {
  if (pushData) {
    window.dataLayer.push(pushData);
  }
  const url = `https://www.facebook.com/sharer.php?u=${shareUrl}`;
  popupWindow(url, popupOptions);
}

export function shareLinkToTwitter(
  shareUrl,
  shareTitle = null,
  pushData = null,
  shareCountUrl = null,
  popupOptions = null
) {
  if (pushData) {
    window.dataLayer.push(pushData);
  }
  if (!shareTitle) {
    shareTitle = document.title;
  }
  const url = `https://twitter.com/intent/tweet?hashtags=&original_referrer=&text=${encodeURI(
    shareTitle
  )}&url=${shareUrl}${shareCountUrl ? `&counturl=${shareCountUrl}` : ''}`;
  popupWindow(url, popupOptions);
}

export function copyShareLink(url, pushData = null) {
  if (pushData) {
    window.dataLayer.push(pushData);
  }
  copyStringToUserClipboard(url);
}

export function copyStringToUserClipboard(textString) {
  const el = document.createElement('textarea');
  el.value = textString;
  el.setAttribute('readonly', '');
  // next two lines are to make sure the textarea won't be seen
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}
