// Segment Configuration
export class DataLayer {
  get partners() {
    return ['vista', 'pb', 'pbk', 'ws'];
  }

  get keys_saved() {
    return ['session_id', 'surround_id'];
  }

  get storage_key() {
    return 'client_data_layer_saved2';
  }

  constructor(client_data) {
    this.dl = {...client_data};
    this.dl.page_id = new Date().getTime();
    this.sync();
  }

  load() {
    // load previous data from storage
    if (!localStorage) return;
    let stored = localStorage.getItem(this.storage_key);
    if (!stored) return;
    stored = JSON.parse(stored);
    for (const key of Object.keys(stored)) {
      if (!this.dl[key]) {
        this.dl[key] = stored[key];
      }
    }
  }

  ext() {
    const s = {};
    if (this.dl) {
      for (const name of Object.keys(this.dl)) {
        if (name !== 'email') {
          s[name] = this.dl[name];
        }
      }
    }
    return s;
  }

  save(push = true) {
    // Saves to local storage and updates the data layer
    if (!localStorage) return;
    const v = {}; // copy so we don't change it
    // some stuff we don't want to save
    for (const key of this.keys_saved) {
      if (Object.keys(this.dl).indexOf(key) >= 0) {
        v[key] = this.dl[key];
      }
    }
    const key = this.storage_key;
    localStorage.setItem(key, JSON.stringify(v));
    // save the last key so we can find it on pages where the server doesn't know the key
    if (push) {
      return window.dataLayer.push(this.ext());
    }
  }

  sync() {
    // sync storage - don't overwrite any value provided from the server
    // this exists to persist values between page loads even when the server doesn't provide them.
    try {
      this.load();
      this.save();
    } catch (e) {
      if (Sentry) {
        Sentry.captureException(e);
      }
    }
  }

  push(name, value) {
    // set a value in the gtm datalayer and client
    this.dl[name] = value;
    const p = {};
    p[name] = value;
    window.dataLayer.push(p);
    return this;
  }

  page_event(url, name) {
    try {
      if (!name && url) {
        name = url;
        url = undefined;
      }
      if (!url) {
        url = window.location.pathname;
      }

      const pos = url.indexOf('?');
      if (pos > -1) {
        url = url.substring(0, pos);
      }
      if (url.length > 1 && url.match(/\/$/)) {
        url = url.substring(0, url.length - 1);
      }

      window.dataLayer.push({
        event: `${url};${name}`,
      });
    } catch (error) {
      if (window.console) {
        // console.log(error);
      }
    }
  }

  track_event(name, data) {
    if (!data) {
      data = {};
    }

    for (const key of Object.keys(data)) {
      this.dl[key] = data[key];
    }
    this.save(false);

    //  adding events for soasta
    if (window.track_event) {
      window.track_event[name] = data;
    }

    data.event = name;
    window.dataLayer.push(data);
  }

  ga_event(opts) {
    // send an event to analytics
    // example:
    //    tags.ga_event
    //      eventCategory: 'Template Performance'
    //      eventAction: window.event_data.event_type
    //      eventLabel: window.event_data.template_name
    //      eventValue: guest_list.length

    if (window.track_event) {
      window.track_event[`ga_${opts.eventCategory}`] = opts;
    }

    let eventName = 'GAEvent';
    if (opts.eventName) {
      eventName = opts.eventName;
    }

    window.dataLayer.push({
      event: eventName,
      eventCategory: opts.eventCategory,
      eventAction: opts.eventAction,
      eventLabel: opts.eventLabel,
      eventValue: opts.eventValue,
    });
  }

  set_adid(adid) {
    // this is called from the fif.html page with -1. code is ported from razor's ads.js
    let sid = this.dl.surround_id;
    if (adid !== -1) sid = adid;

    if (sid <= 0) sid = undefined;
    this.push('surround_id', sid);
    this.save(false);
  }

  h() {
    if (!this.dl.email || !this.dl.user_id) {
    }
  }
}
