function initAdServer(resolve) {
  if (pbjs.initAdserverSet) {
    resolve();
    return;
  }
  pbjs.initAdserverSet = true;
  window.googletag.cmd.push(() => {
    pbjs.que.push(() => {
      pbjs.setTargetingForGPTAsync();
      resolve();
    });
  });
}

export const prebidPromise = async (adArray) => {
  window.pbjs = window.pbjs || {};
  window.pbjs.que = window.pbjs.que || [];
  return new Promise((resolve, reject) => {
    window.pbjs.que.push(() => {
      const config = {
        useBidCache: true,
        enableSendAllBids: true,
        priceGranularity: {
          buckets: [
            {
              precision: 2, // default is 2 if omitted - means 2.1234 rounded to 2 decimal places = 2.12
              min: 0,
              max: 50,
              increment: 0.01,
            },
          ],
        },
        floors: {
          enforcement: {
            floorDeals: true, // default to false
          },
        },
        schain: {
          validation: 'strict',
          config: {
            ver: '1.0',
            complete: 1,
            nodes: [
              {
                asi: 'evite.com',
                sid: 1,
                hp: 1,
              },
            ],
          },
        },
        userSync: {
          userIds: [
            {
              name: 'identityLink',
              params: {
                pid: '27',
              },
              storage: {
                type: 'cookie',
                name: '_lr_env',
                expires: 1,
              },
            },
          ],
          syncDelay: 3000,
          syncEnabled: true,
          iframeEnabled: true,
          syncsPerBidder: 8,
          filterSettings: {
            iframe: {
              bidders: '*',
              filter: 'include',
            },
            image: {
              bidders: '*',
              filter: 'include',
            },
          },
        },
      };
      if (window.client_data && window.client_data.user_identifier) {
        config.user = {
          id: window.client_data.user_identifier,
        };
      }
      pbjs.setConfig(config);
      pbjs.adUnits = [];

      pbjs.requestBids({
        bidsBackHandler: () => initAdServer(resolve),
        timeout: evite.dfp.PREBID_TIMEOUT,
      });
    });
    setTimeout(() => {
      // Failsafe if Prebid doesn't load
      initAdServer(resolve);
    }, evite.dfp.PREBID_TIMEOUT);
  });
};
