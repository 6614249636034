import {reaction, observable, when, computed, runInAction} from 'mobx';

export function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function processPrebidResponses(prebidWinners, auctionWinners, responses) {
  return responses.map((bid) => ({
    ad: bid.ad,
    adId: bid.adId,
    adUnitCode: bid.adUnitCode,
    bidder: bid.bidder,
    bidderCode: bid.bidderCode,
    bidWinner: !!auctionWinners.find((winnerId) => bid.adId === winnerId),
    prebidWinner: !!prebidWinners.find((winnerId) => bid.adId === winnerId),
    cpm: parseFloat(bid.cpm),
    height: parseInt(bid.height, 10),
    pbAg: parseFloat(bid.pbAg),
    pbDg: parseFloat(bid.pbDg),
    pbHg: parseFloat(bid.pbHg),
    pbLg: parseFloat(bid.pbLg),
    pbMg: parseFloat(bid.pbMg),
    requestId: bid.requestId,
    requestTimestamp: parseInt(bid.requestTimestamp, 10),
    responseTimestamp: parseInt(bid.responseTimestamp, 10),
    statusMessage: bid.statusMessage,
    timeToRespond: parseInt(bid.timeToRespond, 10),
    width: parseInt(bid.width, 10),
  }));
}

export function getPrebidData() {
  if (!window.pbjs) return [];
  const prebidWinnerIds = window.pbjs.getAllPrebidWinningBids().map((bid) => bid.adId);
  const auctionWinnerIds = window.pbjs.getAllWinningBids().map((bid) => bid.adId);
  const allResponses = [
    ...Object.values(window.pbjs.getBidResponses()).map((response) => response.bids),
  ].reduce((acc, val) => acc.concat(val), []);
  return processPrebidResponses(prebidWinnerIds, auctionWinnerIds, allResponses);
}

export class EventTracker {
  clientId = observable.box('');

  @observable accessor slotEvents = [];

  @observable accessor eventsFinished = false;

  // default is a window value that is enabled when the new user monitoring is on
  sendEventTracking = window.SEND_EVENT_TRACKING;

  sampleNumber = window.CLIENT_SAMPLE_NUMBER || 3;

  eventBase = {
    blocked: null,
    clientId: this.clientId ? this.clientId : '', // GA client ID
    sessionId: window.client_data ? window.client_data.session_id : '',
    requestId: window.client_data ? window.client_data.request_id : '',
    userId: window.client_data ? window.client_data.user_identifier : null,
    loggedIn: window.client_data ? window.client_data.is_logged_in : false,
    page: window.location.pathname,
    hostname: window.location.hostname,
    userAgent: window.navigator ? window.navigator.userAgent : null,
    a9Data: {},
    prebidData: [],
    timing: [],
  };

  constructor() {
    window.evite.slotEvents = observable(window.evite.slotEvents);
    const clientIdInterval = setInterval(() => {
      runInAction(() => {
        if (window.ga && window.ga.getAll) {
          this.clientId.set(window.ga.getAll()[0].get('clientId'));
          clearInterval(clientIdInterval);
        }
      });
    }, 100);

    // send data after 10 seconds no matter what
    setTimeout(() => {
      if (this.eventsFinished) return;
      this.processPrebid();
      evite.trackTimingEvent('trackingFinalTimeout');
      this.eventsFinished = true;
    }, window.CLIENT_TRACKING_TIMEOUT || 10000);

    reaction(
      () => this.clientId.get(),
      (clientId, prevValue, raxion) => {
        this.eventBase.clientId = clientId;
        if (clientId) {
          const lastClientIdDigit = Number(clientId.substr(clientId.length - 1));
          if (lastClientIdDigit < this.sampleNumber) {
            this.sendEventTracking = true;
          }
        }
        raxion.dispose();
      }
    );

    when(
      () => this.numberOfSlotsFinished === window.evite.dfp.autoAds.length,
      () => {
        this.processPrebid();
        evite.trackTimingEvent('slotsAllRenderEnded');
        this.eventsFinished = true;
      }
    );

    when(
      () => this.eventsFinished,
      () => this.sendData()
    );

    window.addEventListener(
      'beforeunload',
      () => {
        if (!this.eventsFinished) {
          evite.trackTimingEvent('navigateBeforeFinished');
          this.sendData();
        }
      },
      false
    );

    window.googletag.cmd.push(() => {
      this.eventBase.blocked = false;
      window.dataLayer.push({ads_blocked: false});
      window.BOOMR = window.BOOMR || {};
      if (window.BOOMR.addVar) window.BOOMR.addVar('evt_blocked', false);
    });

    evite.when('window.load').then(() => {
      if (this.eventBase.blocked !== null) return;
      this.eventBase.blocked = true;
      window.dataLayer.push({ads_blocked: true});
      window.BOOMR = window.BOOMR || {};
      if (window.BOOMR.addVar) window.BOOMR.addVar('evt_blocked', true);
    });
  }

  processPrebid = () => {
    const prebidData = getPrebidData();
    prebidData.forEach((data) => {
      evite.trackSlotEvent('prebidDataCollected', {
        slotId: data.adUnitCode,
        prebidData: data,
      });
    });
  };

  processEvents = () => {
    this.slotEvents = window.evite.dfp.autoAds.map(this.createSlotEvent);
    this.slotEvents = this.slotEvents.map((slot) => {
      window.evite.slotEvents.forEach((event) => {
        const {prebidData, timing, ...rest} = event;
        if (event.slotId === slot.slotId) {
          if (prebidData) {
            slot.prebidData.push(deepCopy(prebidData));
          }
          if (event.timing) {
            slot.timing.push({...timing});
          }
          slot = {...slot, ...deepCopy(rest)};
        }
      });
      return slot;
    });
    window.evite.timingEvents.forEach((event) => {
      this.updateAllSlotEvents(event);
    });
  };

  @computed
  get numberOfSlotsFinished() {
    let accBase = 0;
    if (window.client_data.zone === 'gallery' || window.client_data.zone === 'myevite') {
      accBase += 1;
    }
    return window.evite.slotEvents.reduce((acc, e) => {
      if (e.timing && e.timing.eventName && e.timing.eventName === 'slotOnload') {
        return acc + 1;
      }
      return acc;
    }, accBase);
  }

  createSlotEvent = (e) => ({...this.eventBase, ...e});

  updateAllSlotEvents(updateEvent) {
    this.slotEvents = this.slotEvents.map((slotEvent) => {
      const {timing, ...rest} = updateEvent;
      if (timing) {
        slotEvent.timing.push(timing);
      }
      return {...slotEvent, ...deepCopy(rest)};
    });
  }

  sendData = () => {
    this.processEvents();
    if (this.sendEventTracking && window.navigator) {
      const data = new FormData();
      data.append('bq', JSON.stringify(this.slotEvents));
      if (window.location.host !== 'local.evitelabs.com')
        window.navigator.sendBeacon('/eventhorizon/event', data);
    }
  };
}

const trackedNumbers = new Map();
export function trackInternationalContactError({phoneNumber: phone, countryCode, eventId}) {
  const allNonNumbers = /\D+/g;
  const phoneNumber = phone.replace(allNonNumbers, '');

  if (eventId && countryCode && phoneNumber && !trackedNumbers.has(phoneNumber)) {
    trackedNumbers.set(phoneNumber, true);
    window.dataLayer.push({
      event: 'international-contact-error',
      'event-id': eventId,
      'phone-number': `${countryCode}|${phoneNumber}`,
    });
  }
}
