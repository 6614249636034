import {extendObservable} from 'mobx';
import {DataLayer} from '~/ads/DataLayer';
import {EventTracker} from './tracking';
import {DfpStore} from '~/ads/dfp';
import {AtsMock} from './AtsMock';
import {loadMatrices} from '~/ads/matrix/loadMatrices';

try {
  window.dl = new DataLayer(window.client_data);
  window.tags = window.dl;
} catch (e) {
  Sentry.captureException(e);
}

window.ats = AtsMock;
const initATS = () => {
  fetch(`https://api.rlcdn.com/api/identity/envelope?pid=27&it=4&iv=${window.client_data.evc_s}`)
    .then((response) => {
      if (!response.ok) throw response.statusText;
      return response.json();
    })
    .then((json) => {
      const date = new Date();
      date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);
      document.cookie = `_lr_env=${json.envelope}; expires=${date.toUTCString()};`;
    })
    .catch((e) => {
      console.log(`error from rlcdn: ${e}`);
    });
};

// user is logged in & cookie not set
if (
  window.client_data.evc_s &&
  !window.evite.cookie('_lr_env') &&
  !window.DISABLE_PROGRAMMATIC_ADS &&
  !window.ad_free
) {
  initATS();
}

evite.when('document.ready').then(() => {
  if (window.DISABLE_PROGRAMMATIC_ADS) return;
  const tempVars = {...evite.dfp};
  evite.dfp = new DfpStore();
  window.refreshAds = evite.dfp.refreshAds;
  extendObservable(evite.dfp, tempVars);
  (async () => {
    evite.dfp.matrix = await loadMatrices();
    evite.resolve('dfp-matrix');
  })();
  window.evite.eventTracker = new EventTracker();
});

evite.when('dfp').then(() => {
  evite.requestIdleCallback(() => {
    window.tags.track_event('tag_data_loaded');
  });
});
